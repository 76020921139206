import React from 'react';
import ReactDOM from 'react-dom';
import Template from './pages/Home3';

const showComingSoon = true;

ReactDOM.render(
    <>
      {showComingSoon === true || window.location.toString().includes("login") ? window.location.replace('https://propertycoaching.com') : <Template/>}
    </>,
  document.getElementById('root')
);

